<template>
  <div>
    <Header />
    <!-- 搜索 -->
    <Search />
    <div class="logopic w-1920">
      <img
        class="logo-img"
        src="../../../assets/home/industry/logo1.jpg"
        alt=""
      />
    </div>
    <div class="nav w-1920">
      <div class="menu w-1200">
        <div
          class="moren"
          :class="[
            { gaibian: index == TabIndex },
            { 'border-right-line': index != TabList.length - 1 },
          ]"
          v-for="(item, index) in menu"
          :key="index"
          @mouseover="selectedTabItem(index, $event)"
          @click="handleSearchClick(index, item.prodCatId)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="con w-1920">
      <div class="tishi" v-if="list == ''">{{ tishi }}</div>
      <div class="conten w-1200">
        <Loading v-if="isLoading" />
        <div
          class="list"
          v-for="(res, i) in list"
          :key="i"
          v-else
          @click="detail(res.storeId, res.spuId)"
        >
          <div class="pic">
            <Uimage :src="res.url" alt="" />
          </div>
          <div class="name">
            {{ res.name }}
          </div>
          <div class="jifen">市场价： ￥{{ res.priceSection }}</div>
          <div class="jifen">{{ $t('common.Redemption_required') }} {{ res.exchangeScore2 }}{{ $t('common.points') }}</div>
          <div class="num">
            <i class="el-icon-coin"></i> {{ $t('common.Cumulative_conversions') }}{{ res.exchangeNum }}
          </div>
        </div>
        <div class="fenye" v-if="list != ''">
          <el-pagination
            @current-change="pagechange"
            :current-page="page.current"
            style="text-align: right;margin-top: 20px"
            background
            :total="page.total"
            :page-size="page.size"
            layout="prev, pager, next, jumper, ->, total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="kongbai"></div>
    <Foot />
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import Cart from '@/components/cart.vue';
import { post, get } from '@/utils/request';
import Search from '@/components/home/search.vue';
import Foot from '@/components/foot.vue';
export default {
  components: {
    Header,
    Search,
    Cart,
    Foot,
  },
  data() {
    return {
      isLoading: true,
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
      list: [],
      tishi: this.$t('message.shortmessage-041'),
      menu: [
        {
          name: this.$t('commmon.Reference_material'),
        },
        {
          name: this.$t('commmon.food'),
        },
      ],
      TabIndex: 0,
      TabList: [],
      prodCatId: '',
    };
  },
  created() {
    this.getmenu();
  },
  methods: {
    //详情
    detail(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
        query: {
          jifen: 1,
        },
      });
      window.open(href, '_blank');
    },
    getmenu() {
      get('f/api/frontcategory/getcategorylist?pageSize=10').then((res) => {
        this.isLoading = false;
        if (res.data.data) {
          this.menu = res.data.data;
          this.prodCatId = 211;
          res.data.data.forEach((item, index) => {
            if (item.prodCatId==this.prodCatId) {
              this.TabIndex=index
            }
          })
          
          this.getlist(this.prodCatId);
        }
      });
    },
    getlist(e) {
      get(
        'api/storeCategory/getExchangeProductByCategory?storeId=&prodCatId=' +
          e +
          '&pageNum=1' +
          '&pageSize=10'
      ).then((res) => {
        this.isLoading = false;
        if (res.data.data) {
          this.page.total = res.data.data.total;
          this.list = res.data.data.list;
        }
      });
    },
    selectedTabItem(index, $event) {},
    handleSearchClick(index, e) {
      if (e) {
        this.page.current = 1;
        this.prodCatId = e;
        this.TabIndex = index;
        this.getlist(e);
      } else {
        return;
      }
    },
    pagechange(p) {
      this.page.current = p;
      get(
        'api/storeCategory/getExchangeProductByCategory?storeId=&prodCatId=' +
          this.prodCatId +
          '&pageNum=' +
          p +
          '&pageSize=10'
      ).then((res) => {
        this.isLoading = false;
        if (res.data.data) {
          this.list = res.data.data.list;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fenye {
  width: 1200px;
  margin: auto;
}
.tishi {
  width: 90%;
  min-height: 300px;
  text-align: center;
  padding-top: 20px;
  font-size: 24px;
  color: rgb(139, 135, 135);
  padding-top: 100px;
}
.logopic {
  width: 100%;
  height: 400px;
  .logo-img {
    width: 100%;
    height: 100%;
  }
}
.nav {
  width: 100%;
  height: 34px;
  border-top: 4px solid #ff902a;
  background: #f5f5f5;
  .menu {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .moren {
      font-size: 16px;
      color: #282828;
      line-height: 38px;
      padding: 0 8px;
      cursor: pointer;
      img {
        margin-right: 10px;
      }
    }
    .gaibian {
      background: #ff902a;
      color: #fff;
      font-size: 16px;
      line-height: 38px;
      padding: 0 10px;
      img {
        margin-right: 10px;
      }
    }
  }
}
.con {
  background-color: #f5f5f5;
  .conten {
    display: flex;
    flex-wrap: wrap;
    .list {
      width: 230px;
      height: 378px;
      background-color: #fff;
      border: 1px solid #e0e9f0;
      border-radius: 5px;
      margin-top: 20px;
      margin-left: 8px;
      .pic {
        height: 233px;
        width: 230px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        padding: 0 15px;
        margin-top: 20px;
        font-size: 14px;
        color: #323232;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        cursor: pointer;
      }
      .jifen {
        font-size: 13px;
        color: #ff902a;
        margin-left: 15px;
        margin-top: 10px;
      }
      .num {
        font-size: 12px;
        color: #b6b6b6;
        margin-left: 15px;
        margin-top: 20px;
      }
    }
  }
}
</style>
